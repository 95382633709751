<template>
  <ul class="kt-menu__nav">
    <template v-for="(menu, i) in menuItems">
      <KTMenuItem :menu="menu" :key="i"></KTMenuItem>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

import KTMenuItem from "@/views/theme/header/MenuItem.vue";
import ChangeUser from "@/components/mixins/change-user.mixin";

export default {
  name: "KTHeaderMenu",
  mixins: [ChangeUser],
  components: {
    KTMenuItem,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    menuItems() {
      const { changeUser, currentUser } = this;
      return [
        {
          title: "Eraintranet'e Dön.....",
          root: true,
          preIcon: "chevron-left",
          onclick: () =>
            window.location.replace("https://eraintranet.erakolejleri.com/"),
        },
        {
          title: currentUser.NAME_WITH_ROLE,
          root: true,
          preIcon: "male",
          onclick: () => changeUser(),
          class: { "d-lg-none": true },
        },
      ];
    },
  },
};
</script>
